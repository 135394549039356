import * as Sentry from '@sentry/browser';
import PartySocket from 'partysocket';
import './styles.css';

declare const PARTYKIT_HOST: string;

const instances = [
	'alpha',
	'beta',
	'gamma',
	'delta',
	'epsilon',
	'zeta',
	'eta',
	'theta',
	'iota',
	'kappa',
	'lambda',
	'mu',
	'nu',
	'xi',
	'omicron',
	'pi',
	'rho',
	'sigma',
	'tau',
	'upsilon',
	'phi',
	'chi',
	'psi',
	'omega',
];

Sentry.init({
	dsn: 'https://283ca1f3b54ed3927688232c3f9d0b37@d3ct5esellfhvr.cloudfront.net/4507499794857984',
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: instances.map(
		(instance) => `https://${instance}.thewignesh.partykit.dev`,
	),
	// Session Replay
	replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const output = document.getElementById('app') as HTMLDivElement;

instances.forEach((instance) => {
	const preElemt = document.createElement('pre');
	preElemt.id = `${instance}-code`;
	// preElemt.style.color = '#FF0000';
	preElemt.textContent = `🎈 Connecting to ${instance}...`;
	output.appendChild(preElemt);

	const conn = new PartySocket({
		host: `${instance}.thewignesh.partykit.dev`,
		room: 'room',
	});

	setInterval(() => {
		conn.send(JSON.stringify({ time: Date.now() }));
	}, 1000);

	conn.addEventListener('message', (event) => {
		const data = JSON.parse(event.data);
		if (data.type !== 'stats') return;
		const now = Date.now();
		const latency = Math.abs(now - data.time) / 2;
		delete data.time;
		preElemt.textContent = JSON.stringify(
			{
				...data,
				latency: `${latency}ms ${
					latency > 500 ? '🔴' : latency > 300 ? '🟡' : '🟢'
				}`,
				updated: new Date().toLocaleString(),
			},
			null,
			2,
		);
	});
	conn.addEventListener('open', (event) => {
		preElemt.textContent = `🎉 Connected to ${instance}`;
	});
	conn.addEventListener('close', ({ reason }) => {
		const closeReason = (reason as unknown as CloseEvent).reason;
		preElemt.textContent = `💔 Disconnected from ${instance} duue to ${closeReason}`;
	});
});
